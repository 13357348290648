<template>
  <v-card>
    <v-card-title>{{ $t("Gestion des Attributs de la catégory") }}</v-card-title>

    <v-card-text>
      <v-select
        v-model="productattribute.attribute_id"
        :label="$t('Selectionner un type')"
        :items="attributes"
        item-text="name"
        item-value="id"
        dense
        outlined
      />

      <v-text-field
        id="text"
        v-model="productattribute.value"
        outlined
        dense
        autocomplete="off"
        type="text"
        name="text"
        :label="$t('Value')"
      />

      <v-btn
        block
        depressed
        small
        color="primary"
        :loading="loading"
        @click="create()"
      >
        {{ $t('Ajouter') }}
      </v-btn>
      <div class="form-group mt-3">
        <div class="text-h6">
          {{ $t("List des Attributs") }}
        </div>
        <v-simple-table
          style="max-height: 290px"
          class="overflow-y-auto"
        >
          <thead>
            <tr>
              <th>{{ $t("Attribute") }}</th>
              <th>{{ $t("Value") }}</th>
              <th>{{ $t("action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(attribute, $index) in productattributes"
              :key="$index"
            >
              <td>{{ attribute.attribute.name }}</td>
              <td>{{ attribute.value }}</td>
              <td>
                <v-btn
                  icon
                  small
                  :loading="loading"
                  @click="_delete(attribute, $index)"
                >
                  <v-icon color="red">
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

  export default {
    name: 'ProductFormAttribute',
    props: {
      product: {
        type: Object,
        default: () => {},
      },
      productattributes: {
        type: Array,
        default: () => [],
      },
      attributes: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        selected: null,
        loading: false,
        productattribute: {},
        value: '',
        product_id: '',
      }
    },
    mounted () {
      this.$root.$on('updateattribute', (product, attributes, productattributes) => {
        this.product = product
        this.attributes = attributes
        this.productattributes = productattributes
      })
    },
    methods: {

      create () {
        this.productattribute.product_id = this.product.id
        // this.productattribute.attribute_id = this.productattribute.attribute.id
        this.productattribute.category_id = this.product.category_id
        const productattribute = this.productattribute

        console.log(productattribute)
        this.loading = true
        // eslint-disable-next-line no-undef
        axios.post('/product-attribute', productattribute)
          .then((response) => {
            console.log(response)
            this.value = ''
            this.productattributes.push(response.data.productattribute)
          }).catch(function (error) {
            console.log(error)
          }).then(() => {
            this.loading = false
          })
      },
      update (attribute) {
        // eslint-disable-next-line no-undef
        axios.post('/admin/attribute/' + attribute.id, attribute)
          .then(function (response) {
            console.log(response)
          }).catch(function (error) {
            console.log(error)
          })
      },
      _delete (attribute, index) {
        if (!confirm('Confirmer la suppression?')) { return }

        this.loading = true
        // eslint-disable-next-line no-undef
        axios.get('/product-attribute/delete/' + attribute.id, attribute)
          .then((response) => {
            console.log(response)
            // todo: remove line
            this.productattributes.splice(index, 1)
          }).catch(function (error) {
            console.log(error)
          }).then(() => {
            this.loading = false
          })
      },
    },

  }
</script>
